export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);

        function handleObserver([entry], obs) {
            if (el.classList.toggle('in-view', entry.isIntersecting)) {
                obs.disconnect();
            }
        }

        const observer = new IntersectionObserver(handleObserver);
        observer.observe(el);
    }
}
