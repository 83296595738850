import { Chart } from 'chart.js';

function getNonQOF(money, yearlyReturn) {
    const resultOf2 = (money * 0.238);
    const resultOf3 = money - resultOf2;
    const compound = (1 + yearlyReturn) ** 10;
    const resultOf4 = resultOf3 * compound;
    const resultOf5 = resultOf3 * 0.15;
    const resultOf6 = resultOf4 - resultOf5;
    const resultOf7 = resultOf6 * 0.238;
    const nonQOF = resultOf6 - resultOf7;

    return nonQOF;
}

function getQOF(money, yearlyReturn) {
    const compound = (1 + yearlyReturn) ** 10;
    const resultOf2 = (money * compound);
    const resultOf3 = money * 0.238;
    const resultOf4 = resultOf3 * 0.9;
    const resultOf5 = money * 0.15;
    const qof = resultOf2 - resultOf4 - resultOf5;

    return qof;
}

export default class {
    constructor() {
        const submit = document.getElementById('submit');
        const ctx = document.getElementById('myChart').getContext('2d');
        const invest = document.getElementById('invest');
        const percent = document.getElementById('percent');
        const savings = document.getElementById('savings');
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        const chart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ['Non-QOF Investment', 'QOF Investment'],
                datasets: [{
                    data: [0, 0],
                    backgroundColor: [
                        'rgba(113, 113, 113, 1)',
                        'rgba(0, 129, 109, 1)',
                    ],
                    borderColor: [
                        'rgba(113, 113, 113, 1)',
                        'rgba(0, 129, 109, 1)',

                    ],
                    borderWidth: 1,
                }],
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                layout: {
                    padding: {
                        left: 25,
                        right: 25,
                        bottom: 50,
                    },
                },
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        });

        function getTaxSavings(e) {
            e.preventDefault();

            const money = parseFloat(invest.value, 10);
            const yearlyReturn = parseFloat(percent.value, 10);

            if (Number.isNaN(money)) {
                invest.value = '';

                alert('Please enter a number to invest.'); /* eslint-disable-line no-alert */

                return;
            }

            const nonQOF = getNonQOF(money, yearlyReturn);
            const qof = getQOF(money, yearlyReturn);

            savings.innerHTML = formatter.format(qof - nonQOF);
            chart.data.datasets[0].data = [nonQOF, qof];
            chart.update();
        }

        submit.addEventListener('click', getTaxSavings);
    }
}
