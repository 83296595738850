import $ from 'jquery';

export default class {
    constructor() {
        const addStyle = () => {
            const elementList = $('.column-animated__transform_img');
            Array.from(elementList).map(element => {
                $(element).addClass('column-animated__visible');
                return null;
            });
        };
        $(document).ready(addStyle).on('scroll', addStyle);
    }
}
