import { scroll } from 'ui-utilities';

export default class {
    constructor({
        id,
        elevatorHandle,
        actions,
        events,
    }) {
        const el = document.getElementById(id);
        const firstSection = el.querySelector('article').children[0];
        const elevator = el.querySelector(elevatorHandle);
        const anchors = el.querySelectorAll('a[href*="#"][data-smooth-scroll="true"]:not([data-ready="true"])');
        const inputLinks = el.querySelectorAll('a[data-input-link="true"]');

        function handleElevator() {
            if (window.pageYOffset < firstSection.getBoundingClientRect().bottom) {
                // Subtract 1 to offset to trigger rotate
                // I have hardcoded the header height for now, TODO make more dynamic if needed. -AL
                scroll.bottom(firstSection, 67 - 1);
            } else {
                scroll.top(el, 67 - 1);
            }
        }

        function handleSectionObserver([fs]) {
            elevator.style.transform = `rotate(${fs.isIntersecting ? '0deg' : '-180deg'})`;
        }
        function handleAnchor(e) {
            e.preventDefault();

            scroll.to(e.currentTarget.href.split('#')[1], 67);
        }
        function handleInputLink(e) {
            e.preventDefault();

            const name = e.currentTarget.getAttribute('data-input-name');
            const value = e.currentTarget.getAttribute('data-input-value');

            events.emit(actions.setInputValue, { name, value });
        }

        elevator.addEventListener('click', handleElevator);
        const sectionObserver = new IntersectionObserver(handleSectionObserver, {
            rootMargin: `-67px 0px 0px 0px`,
        });
        sectionObserver.observe(firstSection);
        anchors.forEach(a => {
            a.addEventListener('click', handleAnchor);
            a.setAttribute('data-ready', true);
        });
        inputLinks.forEach(il => {
            il.addEventListener('click', handleInputLink);
        });
    }
}
