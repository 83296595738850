import SwiperCore, { Pagination } from 'swiper/core';

SwiperCore.use([Pagination]);

export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const swiperContainer = el.querySelector('.swiper-container');
        const swiperPagination = el.querySelector('.swiper-pagination');

        const swiper = new SwiperCore(swiperContainer, {
            slidesPerView: 'auto',
            spaceBetween: 10,
            updateOnWindowResize: true,
            slideToClickedSlide: true,
            slidesOffsetAfter: 20,
            watchOverflow: true,
            pagination: {
                el: swiperPagination,
                type: 'fraction',
            },
        });
        swiper.init();
    }
}
