import { router } from 'ui-utilities';

export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const list = el.querySelector('ul');
        const trigger = document.getElementById('button');

        let page = 1;

        function handleSeeMore() {
            page++;

            const url = `${window.location.protocol}//${window.location.host}/shared/news`;
            const query = { page };

            function cb(res) {
                const { info, markup } = JSON.parse(res);

                if (info.currentPage === info.totalPages) {
                    trigger.setAttribute('disabled', true);
                }

                list.innerHTML += markup;
            }

            router.get({ url, query, cb });
        }

        trigger.addEventListener('click', handleSeeMore);
    }
}
