export default class {
    constructor({
        id,
        closeHandle,
        hotspotHandle,
        factHandle,
        activeClass,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const hotspots = el.querySelectorAll(hotspotHandle);
        const facts = el.querySelectorAll(factHandle);
        const closeButtons = el.querySelectorAll(closeHandle);

        function handleKeyup(e) {
            // Only car about escape key
            if (e.keyCode !== 27) return;

            facts.forEach((f) => {
                f.classList.remove(activeClass);
            });

            document.removeEventListener('keyup', handleKeyup);
        }
        function openFact(e) {
            const index = parseInt(e.currentTarget.getAttribute('data-index'), 10);

            facts.forEach((f, i) => {
                f.classList.toggle(activeClass, i === index);
            });

            document.addEventListener('keyup', handleKeyup);
        }

        function closeFacts(e) {
            e.currentTarget.closest(factHandle).classList.remove(activeClass);

            document.removeEventListener('keyup', handleKeyup);
        }

        hotspots.forEach(h => {
            h.addEventListener('click', openFact);
        });
        closeButtons.forEach(c => {
            c.addEventListener('click', closeFacts);
        });
    }
}
