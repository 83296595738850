export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const modalTrigger = el.querySelector('button.open');
        const videoId = el.dataset.videoId;
        const modal = document.getElementById(videoId);
        const closeButton = el.querySelector('button.close');

        function openModal() {
            modal.classList.remove('hidden');
            modal.classList.add('video-modal');
        }

        function closeModal() {
            modal.classList.add('hidden');
            modal.classList.remove('video-modal');
        }

        modalTrigger.addEventListener('click', openModal);
        closeButton.addEventListener('click', closeModal);
    }
}
