import { load } from 'recaptcha-v3';

const RECAPTCHA_SITE_KEY = '6LfbjxsbAAAAAL6N_7MY-3eiBCyqMkXaAg6saZy-';

export default class {
    constructor({
        id,
    }) {
        load(RECAPTCHA_SITE_KEY).then(r => {
            const el = document.getElementById(id);
            const form = el.querySelector('form');

            function handleSubmit(e) {
                e.preventDefault();

                const currentForm = e.currentTarget;
                const submit = currentForm.querySelector('[type="submit"]');

                submit.setAttribute('disabled', true);

                r.execute('form').then(token => {
                    const input = document.createElement('input');
                    input.setAttribute('type', 'hidden');
                    input.setAttribute('name', 'token');
                    input.setAttribute('value', token);
                    currentForm.appendChild(input);
                    currentForm.submit();
                });
            }

            form.addEventListener('submit', handleSubmit);
        });
    }
}
