import pop from 'compop';
import './polyfills';

// Components
import ColumnAnimated from './components/column-animated';
import Calculator from './components/calculator';
import Form from './components/form';
import Header from './components/header';
import ImageSplits from './components/image-splits';
import Input from './components/input';
import Main from './components/main';
import Modal from './components/modal';
import Map from './components/map';
import Navigation from './components/navigation';
import NewsListing from './components/news-listing';
import OverlflowCarousel from './components/overlflow-carousel';
import Timeline from './components/timeline';
import VideoCard from './components/video-card';

const SITE_HANDLE = 'pb';

// Define map of component handles to component classes
/* eslint-disable quote-props */
const classMap = {
    'column-animated': ColumnAnimated,
    'calculator': Calculator,
    'form': Form,
    'header': Header,
    'image-splits': ImageSplits,
    'input': Input,
    'main': Main,
    'modal': Modal,
    'map': Map,
    'navigation': Navigation,
    'news-listing': NewsListing,
    'overlflow-carousel': OverlflowCarousel,
    'timeline': Timeline,
    'video-card': VideoCard,
};
/* eslint-enable quote-props */

// Define all actions/commands that components pub/sub
const actions = {
    loadModal: 'LOAD_MODAL',
    openModal: 'OPEN_MODAL',
    closeModal: 'CLOSE_MODAL',
    lockScroll: 'LOCK_SCROLL',
    unlockScroll: 'UNLOCK_SCROLL',
    setInputValue: 'SET_INPUT_VALUE',
    setInputError: 'SET_INPUT_ERROR',
};

// Event handler functions
function handleDOMConentLoaded() {
    const scaffold = window[SITE_HANDLE];

    // Functionality for after components initialize
    function cb() {
        // ...
    }

    // Call component constructors
    pop({ scaffold, classMap, actions, cb });
}

// Add event listeners
document.addEventListener('DOMContentLoaded', handleDOMConentLoaded);
