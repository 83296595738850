import SwiperCore, { Pagination } from 'swiper/core';

// configure Swiper to use modules
SwiperCore.use([Pagination]);

export default class {
    constructor({
        id,
        backgroundImageHandle,
        activeClass,
    }) {
        const el = document.getElementById(id);
        const backgroundImages = el.querySelectorAll(backgroundImageHandle);
        const swiper = new SwiperCore('.swiper-container', {
            autoplay: 5000,
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            watchSlidesVisibility: true,
        });

        function handleSlideChange(s) {
            backgroundImages.forEach((b, i) => b.classList.toggle(activeClass, i === s.activeIndex)); /* eslint-disable-line max-len */
        }

        swiper.on('slideChange', handleSlideChange);
    }
}
